@import url('https://fonts.googleapis.com/css?family=Lobster|Roboto&display=swap');
/*Initially based on:
https://github.com/simonjsuh/Wikipedia-Search-API-using-React.js
 */

* {
  margin: auto;
  padding: 0;
  box-sizing: content-box;
}

.App {
  width: 1100px;
  margin: 0 auto;
  text-align: center;
  /*font-size:25px;*/
  font-family: 'Roboto', sans-serif;
  margin-top: 50px;
}
h1 {
  font-size: 50px;
  font-family: 'Lobster', cursive;
  margin-bottom: 20px;
}
.App form {
  display: inline-block;
  /*border : solid black;*/
  /*height: auto;*/
  /*width: 600px;*/
  /*border-radius: 20px;*/
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.App input {
  /*line-height: 40px;*/
  /*border : solid black;*/
  /*height: 40px;*/
  /*font-size:15px;*/
  /*border-radius: 0 0 0 0;*/
  vertical-align: top;
  border: none;
  /*width: 400px;*/
  padding-left: 10px;
  padding-right: 250px;
  padding-top: 10px;
  padding-bottom: 7px;
}
.App input:focus {
  outline: none;
}
.App button {
  /*line-height: 40px;*/
  /*height:10px;*/
  /*font-size:15px;*/

  /*border : solid black;*/
  vertical-align: top;
  border: none;
  background-color: #1EABFF;
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  /*border-radius: 0 0 0 0;*/
  cursor: pointer;
}
.App button:focus {
  outline: none;
}
.App select {
  vertical-align: top;
  /*line-height: 40px;*/
  /*height: 40px;*/
  /*border : solid black;*/
  /*font-size:15px;*/
  border: none;
  background-color: #1EABFF;
  padding-left: 10px;
  padding-right: 12px;
  height: 35px;
  /*border-radius: 0 0 0 0;*/
  cursor: pointer; 
}
.searchResultDiv {
  border-radius: 7px;
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.5);
  width: 1200px;
  height: 160px;
  margin-left: -50px;
  margin-right: auto;
  text-align: left;
  margin-top: 25px;
}
.searchResultDiv:hover {
  transform: scale(1.1);
  /*box-shadow: 0px 0px 10px 4px rgba(30, 171, 255, 1.5);*/
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
}
.searchResultDiv h3 {
  color: #1a0dab;
}
.searchResultDiv h3 a {
  color: #1a0dab;
  overflow-wrap: break-word;
  /*display: inline-block;*/
  line-height: 16px;
  text-decoration: none;
}
.searchResultDiv h3 a:hover {
  border-bottom: 3px solid;
}
.searchResultDiv a:visited {
  color: #1a0dab;
}
.searchResultDiv .link a {
  text-decoration: none;
  overflow-wrap: break-word;
  color: #287e44;
}
.searchResultDiv .link a:hover {
  text-decoration: none;
}
.searchResultDiv .searchmatch {
  font-weight: bold;
}
.searchResultDiv .description {
  color: #414141;
  overflow: auto;
}
.searchResultDiv .date {
  color: #414141;
}
.searchResultDiv .imageContainer {
  float: right;
  overflow: hidden;
  border-radius: 7px;
  /*vertical-align: baseline;*/
  /*border: solid black;*/
  width: 320px;
  height: 160px;
  /*box-shadow: 10px 0px 10px 5px rgba(0, 0, 0, 0.3);*/
}
.searchResultDiv .imageContainer:hover {
  /*transform: scale(1.3);*/
}
.searchResultDiv .imageContainer img {
  height: auto;
  /*border: solid;*/
  border-radius: 7px;
  float: right;
  width: 320px;
  
}